import React from 'react';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styles from '../../assets/cssModules/changePages.module.scss';
import { Box, Typography } from '@material-ui/core';
import DeleteAccount from '../settings/DeleteAccount';
import text from '../../language/text';
import CancelSubscriptionModal from '../modals/CancelSubscriptionModal/CancelSubscriptionModal';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  error: string;
  success: string;
}

function ChangeSubscriptionForm(props: IProps & any) {
  return (
    <React.Fragment>
      <div className={styles.CenterContainer}>
        <div className={styles.ChangePwdContainer}>
          <h1 className={styles.Title}>{text.basic.subscription}</h1>
          <p className={styles.Text}>
            {text.forms.changeSubscriptionForm.changeSubscriptionText}
          </p>
          <div
            className={styles.SubscriptionDatesContainer}
            style={{ marginTop: '15%' }}>
            <Box>
              <Typography className={styles.Text}>
                {text.forms.changeSubscriptionForm.startDate}
              </Typography>
            </Box>
            <Box>
              <Typography className={styles.Dates}>01-01-2021</Typography>
            </Box>
          </div>

          <div className={styles.SubscriptionDatesContainer}>
            <Box>
              <Typography className={styles.Text}>
                {text.forms.changeSubscriptionForm.nextPaymentDate}
              </Typography>
            </Box>
            <Box>
              <Typography className={styles.Dates}>01-01-2022</Typography>
            </Box>
          </div>
          <Box className={styles.FormBtns}>
            <CancelSubscriptionModal />
          </Box>
          <Box>
            <h1 className={styles.Title} style={{ marginTop: '20%' }}>
              {text.forms.changeAccount.deleteAccount}
            </h1>
          </Box>
          <Box className={styles.FormBtns}>
            <DeleteAccount />
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state: any) {
  const { settings } = state;
  const { isFetching, error, success } = settings || {
    isFetching: false,
    error: null,
    success: null
  };

  return {
    isFetching,
    error,
    success
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'ChangeSubscriptionForm'
  })(ChangeSubscriptionForm)
);
