import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import text from '../../../language/text';

import {
  Box,
  Button,
  colors,
  Divider,
  List,
  ListItem,
  Menu,
  Typography
} from '@material-ui/core';

import { Link, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { signOut } from '../../../reducers/authActions';
import { formatMoney } from '../../../utils/formatters';
import AddCreditModal from '../../../components/modals/AddCreditModal/AddCreditModal';
import LanguagesMenu from '../../../components/utils/LanguagesMenu';

import styles from '../../../assets/cssModules/dashboardHeader.module.css';
import ConfirmButton from '../../../components/utils/buttons/ConfirmButton';

function HeaderUserbox(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleSignOut = () => {
    props.dispatch(signOut(true));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { locale } = useParams();

  return (
    <Fragment>
      <Box className={styles.addCreditContainer}>
        <div className={styles.addCreditAlign}>
          <Typography style={{ color: 'white' }}>
            {formatMoney(props.credit)}
          </Typography>
        </div>
        <Box>
          <AddCreditModal />
        </Box>
      </Box>

      {/* deze div is de verticale scheidingslijn boven in de menubalk*/}
      <div className={styles.VerticalDivider}></div>

      {/* deze languagesMenu component bevat de talen die je kunt selecteren*/}
      <LanguagesMenu />

      <Button ////deze btn geeft de ingelogde gebruiker weer, als je op daarop klikt kun je uitloggen of naar settings
        color="inherit"
        onClick={handleClick}
        className={styles.LoggedInUser}>
        {/* <Box>
          <Avatar sizes="44" alt="Emma Taylor" src={avatar5} />
        </Box> */}
        {props.firstName}
        <span className="pl-1 pl-xl-3" style={{ color: 'white' }}>
          <FontAwesomeIcon icon={['fas', 'angle-down']} color={colors.white} />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        MenuListProps={{ disablePadding: true }}
        onClose={handleClose}>
        <div className="dropdown-menu-right overflow-hidden p-0">
          <List className={styles.HeaderUserBox}>
            <Box className={styles.MenuItemsText}>
              <ListItem
                className={styles.ListItemPaddingTop}
                style={{ marginTop: '-7%' }}
                button
                component={Link}
                to={`/${locale}/accountchange`}>
                Account
              </ListItem>
              <ListItem
                className={styles.ListItemPadding}
                button
                component={Link}
                to={`/${locale}/changepassword`}>
                {text.fields.password}
              </ListItem>
              <ListItem
                button
                component={Link}
                to={`/${locale}/changesubscription`}
                className={styles.ListItemPadding}>
                {text.basic.subscription}
              </ListItem>
              {/*<ListItem*/}
              {/*  button*/}
              {/*  component={Link}*/}
              {/*  to={`/${locale}/invitefriend`}*/}
              {/*  className={styles.ListItemPaddingInviteFriend}>*/}
              {/*  {text.forms.inviteFriendForm.title}*/}
              {/*</ListItem>*/}
              <Box className={styles.DividerPositioning}>
                <Divider className={styles.Divider} />
              </Box>
              <ListItem
                className={styles.ListItemPaddingBottom}
                style={{ marginBottom: '-5%' }}
                button
                onClick={handleSignOut}>
                <ConfirmButton
                  buttonStyle={'LogoutBtn'}
                  onClick={handleSignOut}
                  text={text.basic.logout}
                />
              </ListItem>
            </Box>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { auth } = state;
  const { user } = auth || {
    user: {
      username: '',
      firstName: ''
    }
  };
  const { username, firstName, credit, role } = user || {
    username: '',
    firstName: '',
    credit: '-',
    role: ''
  };

  return {
    username,
    firstName,
    credit,
    role
  };
}

export default connect(mapStateToProps)(HeaderUserbox);
