import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { closeModal, openModal } from './ducks/actions';
import text from '../../../language/text';
import Loader from '../../utils/Loader';
import ModalDialog from '../../utils/ModalDialog';
import AddCreditForm from '../../forms/addCreditForm/AddCreditForm';
import { addCredit } from './ducks/operations';
import { submit } from 'redux-form';
import styles from '../../../assets/cssModules/addCreditModal.module.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ConfirmButton from '../../utils/buttons/ConfirmButton';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  open: boolean;
  isChecking: boolean;
  user: any;
}

function AddCreditModal(props: IProps) {
  const { dispatch, open, isFetching, isChecking, success, user } = props;

  const handleOpen = () => {
    dispatch(openModal());
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values: any) => {
    dispatch(addCredit(values.value));
  };

  return (
    <Fragment>
      <Button onClick={handleOpen} style={{ color: 'white' }}>
        {text.modals.addCredit.openButton}
      </Button>
      {isChecking ? (
        <ModalDialog
          open={open}
          onClose={() => {}}
          title={text.modals.addCredit.title}
          actions={[]}>
          <Typography>{text.modals.addCredit.checkStatus}</Typography>
          <LinearProgress value={25} className="mb-3" />
        </ModalDialog>
      ) : success ? (
        <ModalDialog
          open={open}
          onClose={handleClose}
          title={text.modals.addCredit.title}
          actions={[
            <Button onClick={handleClose} color="primary" variant="contained">
              {text.modals.addCredit.successClose}
            </Button>
          ]}>
          <Typography>{text.modals.addCredit.success}</Typography>
        </ModalDialog>
      ) : (
        <ModalDialog
          open={open}
          onClose={handleClose}
          title={text.modals.addCredit.title}
          actions={[
            <List>
              <ListItem>
                <ConfirmButton
                  buttonStyle={'addCreditBtn'}
                  onClick={() => dispatch(submit('AddCreditForm'))}
                  key={2}
                  text={text.modals.addCredit.addCredit}
                />
              </ListItem>
              <ListItem>
                <button
                  className={styles.CancelBtn}
                  onClick={handleClose}
                  key={1}>
                  {text.modals.addCredit.cancel}
                </button>
              </ListItem>
              <ListItem>
                <p></p>
              </ListItem>
            </List>
          ]}>
          <Loader open={isFetching} inElement />
          <Typography className="mb-3">
            {text.modals.addCredit.modalText}
          </Typography>
          <AddCreditForm onSubmit={handleSubmit} />
          {user && user.country == 'NL' ? (
            <Typography className="mt-3">
              {text.modals.addCredit.vatText}
            </Typography>
          ) : (
            <div className="mb-3"></div>
          )}
        </ModalDialog>
      )}
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { components } = state || {
    components: {
      modals: {
        addCreditModal: {
          isFetching: false,
          open: false,
          error: null
        }
      }
    }
  };
  const { modals } = components;
  const { addCreditModal } = modals;
  const { isFetching, error, open, success, isChecking } = addCreditModal;
  return {
    isFetching,
    errorMessage: error,
    success,
    open,
    isChecking,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(AddCreditModal);
