function AddCreditValidator(values: any, state: any) {
  const errors: any = {};

  if (!values.credit) {
    errors.credit = true;
  }

  return errors;
}

export default AddCreditValidator;
