import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  FieldArray,
  Field,
  formValueSelector,
  reset,
  change
} from 'redux-form';
import DynamicForm from '../utils/DynamicForm';
import text from '../../language/text';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  Divider,
  InputAdornment,
  ButtonGroup
} from '@material-ui/core';
import { renderTextField } from '../utils/materialUI';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatMoney } from '../../utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SepaValidator from './SepaValidator';
import { downloadExcel, clearSepa } from '../../reducers/sepaActions';
import { checkIBAN } from '../../utils/ibanChecker';
import { getBIC } from '../../utils/BICFromIBAN';
import moment from 'moment';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  transactions: any[];
  total: number;
  currentBic: string;
  handleBack: () => void;
}

const renderTransactions = connect(() => {
  return {};
})((props: any) => {
  const { fields, dispatch } = props;
  return (
    <Grid container spacing={4}>
      {fields.map((transaction: any, index: number) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={4}>
            {index !== 0 ? (
              <Grid
                item
                xs={12}
                className="m-0 p-0"
                style={{ textAlign: 'right', marginBottom: -10 }}>
                <Button onClick={() => fields.remove(index)}>
                  <FontAwesomeIcon
                    icon={['fas', 'times']}
                    style={{ opacity: 0.8 }}
                  />
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <Typography className="my-3 text-black-50 d-block">
                    {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Field
                    name={`${transaction}.name`}
                    type="text"
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.name}
                    required
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Field
                    name={`${transaction}.iban`}
                    component={renderTextField}
                    label={text.fields.sepa.iban}
                    required
                    variant="outlined"
                    fullWidth
                    onChange={(event: any) => {
                      console.log('transaction', fields.get(index));
                      if (
                        checkIBAN(event.target.value) === 1 &&
                        (!fields.get(index).bic ||
                          fields.get(index).bic.length === 0)
                      ) {
                        dispatch(
                          change(
                            'SepaForm',
                            `transactions[${index}].bic`,
                            getBIC(event.target.value)
                          )
                        );
                      }
                      console.log('value', event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={`${transaction}.bic`}
                    component={renderTextField}
                    label={text.fields.sepa.bic}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.mandateReference`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.mandateId}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.mandateReferenceDate`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.mandateDate}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Field
                    name={`${transaction}.value`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.amount}
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                    normalize={(value: number) => {
                      return value < 0 ? 0 : value;
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Field
                    name={`${transaction}.endToEndId`}
                    component={renderTextField}
                    label={text.fields.sepa.endToEndId}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.remittance`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.description}
                    variant="outlined"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {fields.length > 1 && index + 1 < fields.length ? (
              <Divider className={'mt-2'} />
            ) : null}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button variant="contained" onClick={() => fields.push({})}>
          {text.forms.sepaForm.addTransaction}
        </Button>
      </Grid>
    </Grid>
  );
});

function SepaForm({
  dispatch,
  handleSubmit,
  transactions,
  errorMessage,
  handleBack,
  currentBic,
  total
}: IProps & any) {
  const [isDebit, setIsDebit] = useState(true);

  const handleIsDebitChange = (value: boolean) => {
    setIsDebit(value);
    dispatch(change('SepaForm', 'debit', value));
  };

  let fields: any[] = [
    {
      name: 'collectionDate',
      label: text.fields.sepa.collectionDate,
      type: 'date',
      required: true
    },
    {
      name: 'company',
      label: text.fields.sepa.companyName,
      required: true
    },
    {
      name: 'iban',
      required: true,
      label: text.fields.sepa.iban,
      onChange: (event: any) => {
        if (
          checkIBAN(event.target.value) === 1 &&
          (!currentBic || currentBic.length === 0)
        ) {
          dispatch(change('SepaForm', 'bic', getBIC(event.target.value)));
        }
      }
    },
    {
      name: 'bic',
      required: true,
      label: text.fields.sepa.bic
    },
    {
      name: 'creditorId',
      required: true,
      label: text.fields.sepa.creditorId
    },
    {
      name: 'batch',
      required: true,
      label: text.fields.sepa.batch,
      type: 'select',
      choices: [
        {
          label: text.fields.sepa.batchChoice.asBatch,
          value: 'as_batch'
        },
        {
          label: text.fields.sepa.batchChoice.nonBatch,
          value: 'non_batch'
        }
      ]
    }
  ];

  const debitFields: any[] = [
    {
      name: 'method',
      label: text.fields.sepa.method,
      type: 'select',
      required: true,
      choices: [
        {
          label: text.fields.sepa.methodChoice.normal,
          value: 'CORE'
        },
        {
          label: text.fields.sepa.methodChoice.business,
          value: 'B2B'
        }
      ]
    },
    {
      name: 'frequency',
      label: text.fields.sepa.frequency,
      type: 'select',
      required: true,
      choices: [
        {
          label: text.fields.sepa.frequencyChoice.once,
          value: 'OOFF'
        },
        {
          label: text.fields.sepa.frequencyChoice.continuous,
          value: 'RCUR'
        }
      ]
    }
  ];

  const creditFields: any[] = [
    {
      name: 'paymentType',
      label: text.fields.sepa.paymentType,
      type: 'select',
      required: true,
      choices: [
        {
          label: text.fields.sepa.paymentTypeChoice.nonSalary,
          value: 'OTHR'
        },
        {
          label: text.fields.sepa.paymentTypeChoice.salary,
          value: 'SALA'
        }
      ]
    }
  ];

  if (isDebit) {
    fields = debitFields.concat(fields);
  } else {
    fields = creditFields.concat(fields);
  }

  const handleClear = () => {
    dispatch(reset('SepaForm'));
    dispatch(clearSepa());
  };

  const handleDownloadExcel = () => {
    dispatch(downloadExcel(transactions));
  };

  return (
    <Fragment>
      <Typography color="error" className={'font-weight-bold'}>
        {errorMessage}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} className="pl-0 mt-2">
          <ButtonGroup className="mb-2">
            <Button
              variant="contained"
              color={isDebit ? 'primary' : 'default'}
              onClick={() => handleIsDebitChange(true)}>
              {text.fields.sepa.debit}
            </Button>
            <Button
              variant="contained"
              color={isDebit ? 'default' : 'primary'}
              onClick={() => handleIsDebitChange(false)}>
              {text.fields.sepa.credit}
            </Button>
          </ButtonGroup>
          <Field
            name={'debit'}
            type={'text'}
            component={renderTextField}
            style={{ display: 'none' }}
          />
        </Grid>
        <DynamicForm
          handleSubmit={handleSubmit}
          fields={fields}
          twoColumns
          outlined
          classes={{
            textField: 'my-2'
          }}
        />

        <ExpansionPanel style={{ marginTop: 10 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="font-weight-bold">
                  {text.fields.sepa.payments}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  <b>{text.forms.sepaForm.totalTransactions}: </b>
                  {transactions.length}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography>
                  <b>{text.forms.sepaForm.total}: </b>
                  {!isNaN(total) ? formatMoney(total) : formatMoney(0)}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FieldArray name="transactions" component={renderTransactions} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div
          className="mt-3"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button className="btn" variant="contained" onClick={handleBack}>
              {text.basic.back}
            </Button>
            <Button
              className="btn text-danger mx-3"
              variant="contained"
              onClick={handleClear}>
              {text.pages.dashboard.components.clearForm}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleDownloadExcel}
              className="btn mx-3">
              {text.pages.dashboard.components.downloadExcel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="btn">
              {text.pages.dashboard.components.makeSepaButton}
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

const selector = formValueSelector('SepaForm');

function mapStateToProps(state: any) {
  const { sepa } = state;
  const { error } = sepa || {
    error: null
  };

  let transactions: any[] = selector(state, 'transactions');

  if (!transactions) transactions = [];
  const total = transactions.reduce((total: number, transaction: any) => {
    let number = 0;
    if (!isNaN(transaction.value)) {
      number = Number(transaction.value);
    }
    return total + number;
  }, 0);

  return {
    transactions,
    currentBic: selector(state, 'bic'),
    total,
    isFetching: false,
    errorMessage: error,
    initialValues: {
      collectionDate: state.sepa.values.collectionDate
        ? state.sepa.values.collectionDate
        : moment().format('YYYY-MM-DD'),
      transactions: state.sepa.transactions,
      debit: 'true',
      ...state.sepa.values
    }
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'SepaForm',
    validate: SepaValidator,
    enableReinitialize: true
  })(SepaForm)
);
