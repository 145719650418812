import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import styles from '../assets/cssModules/changePages.module.scss';
import ChangeSubscriptionForm from '../components/forms/ChangeSubscriptionForm';
import { Link, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import text from '../language/text';

function ChangeSubscriptionPage() {
  const { locale } = useParams<any>();
  //functie moet nog worden aangevuld om de verandering van de subscription door te voeren
  const handleSubmit = (values: any) => {
    console.log('values', values);
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        justify={'center'}
        className={styles.WhiteBoxContainer}>
        <Grid item xs={11} className={styles.WhiteBox}>
          <div>
            <Link to={`/${locale}/Dashboard`} className={styles.ToDashboard}>
              <ChevronLeftIcon style={{ color: '#0055ff' }} fontSize="large" />{' '}
              {text.basic.backToDashboard}
            </Link>
          </div>
          <Grid container justify={'center'}>
            <Grid item lg={6}>
              <ChangeSubscriptionForm onSubmit={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(ChangeSubscriptionPage);
